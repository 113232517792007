import type { BasicObject } from "./types"

export const isFunction = (obj: any): obj is Function => typeof obj === "function"
export const isObject = (obj: any): obj is BasicObject => obj !== null && typeof obj === "object"
export const isPromise = (value: any): value is PromiseLike<any> => isObject(value) && isFunction(value.then)

/**
 * Get the first value of object or array
 * @example
 * first({ name: "jack", country: "US"}) // "jack"
 */
export const first = <T>(value?: unknown): T | undefined => {
  if (Array.isArray(value)) return value[0]
  if (isObject(value)) return Object.values(value as object)[0]

  return undefined
}

/**
 * Removes all fields that are undefined, null or empty string
 *
 * @param obj The object to clean
 * @param recursive
 */
export const clean = <T extends BasicObject<any>>(obj: T, recursive = true): Partial<T> => {
  for (const propName in obj) {
    const propValue = obj[propName]

    if (propValue === null || propValue === undefined || propValue === "") {
      delete obj[propName]
    } else if (typeof propValue === "object" && recursive) {
      // Recurse here if the property is another object.
      clean<Partial<T>>(propValue)
    }
  }
  return obj
}

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  if (value === null || value === undefined) return false
  return true
}

type Falsy = null | undefined | false | "" | 0
export const notFalsy = <TValue>(value: TValue | Falsy): value is Exclude<TValue, Falsy> => !!value

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms, undefined))
}

export const nop = <TValue>(value?: TValue) => value

export const nopAsync = async <TValue>(value?: TValue) => value
