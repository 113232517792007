import { useEffect } from "react"

import Datadog, { useDatadog } from "@bounce/datadog"
import { useCookiePreferences } from "@bounce/legal"

import { config } from "@/config"

type DatadogProviderProps = {
  locale: string
  route: string
}

const ERROR_ROUTES = ["/404", "/500", "_error"]

/**
 * Datadog hooks without child to avoid rerenders
 */
export const DatadogProvider = ({ locale, route }: DatadogProviderProps): null => {
  const areFunctionalCookiesEnabled = useCookiePreferences(({ preferences }) => preferences?.functional)
  const isErrorPage = ERROR_ROUTES.includes(route)
  const { loaded: isDatadogLoaded, load: loadDatadog } = useDatadog(config.datadog)

  useEffect(() => {
    if (areFunctionalCookiesEnabled && !isDatadogLoaded) {
      loadDatadog({ defer: !isErrorPage })
    }
  }, [areFunctionalCookiesEnabled, loadDatadog, isDatadogLoaded, isErrorPage])

  useEffect(() => {
    if (!isDatadogLoaded) return

    void Datadog.addSessionContext("application", {
      locale: locale,
      commitSha: config.commitSha,
    })
  }, [isDatadogLoaded, locale])

  return null
}
