import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import React from "react"

type ReactQueryProviderProps = {
  children: React.ReactNode
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Don't run query hooks on the server
      enabled: typeof window !== "undefined",
      // Don't require a network connection to run queries
      networkMode: "offlineFirst",
      staleTime: Infinity, // Don't refetch data unless manually triggered
    },
  },
})

export const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
