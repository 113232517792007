import { ADVERTISING_CATEGORIES, FUNCTIONAL_CATEGORIES } from "@segment/consent-manager/src/consent-manager/categories"
import type { CategoryPreferences, Destination } from "@segment/consent-manager/types/types"

const PRIVACY_POLICY_URL = "https://bounce.com/legal/privacy"

const ALLOW_ALL_PREFERENCES: CategoryPreferences = {
  functional: true,
  marketingAndAnalytics: true,
  advertising: true,
  essential: true,
}

const preferenceMapping = (destinations: Destination[], preferences: CategoryPreferences) => {
  const { marketingDestinations, advertisingDestinations, functionalDestinations } = normalizeDestinations(destinations)

  const destinationPreferences = [
    ...marketingDestinations.map(d => ({ [d.id]: preferences.marketingAndAnalytics })),
    ...advertisingDestinations.map(d => ({ [d.id]: preferences.advertising })),
    ...functionalDestinations.map(d => ({ [d.id]: preferences.functional })),
  ].reduce((acc, d) => ({ ...acc, ...d }), {})

  return {
    destinationPreferences,
    customPreferences: preferences,
  }
}

const normalizeDestinations = (destinations: Destination[]) => {
  const marketingDestinations: Destination[] = []
  const advertisingDestinations: Destination[] = []
  const functionalDestinations: Destination[] = []

  for (const destination of destinations) {
    if (ADVERTISING_CATEGORIES.find(c => c === destination.category)) {
      advertisingDestinations.push(destination)
    } else if (FUNCTIONAL_CATEGORIES.find(c => c === destination.category)) {
      functionalDestinations.push(destination)
    } else {
      // Fallback to marketing
      marketingDestinations.push(destination)
    }
  }

  return { marketingDestinations, advertisingDestinations, functionalDestinations }
}

export { preferenceMapping, PRIVACY_POLICY_URL, ALLOW_ALL_PREFERENCES }
