import React from "react"

export const ImageFilters = () => {
  return (
    <svg
      id="image-filters"
      className="absolute h-0 w-0 overflow-hidden"
      aria-hidden="true"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter
          id="blue-filter"
          x="-10%"
          y="-10%"
          width="120%"
          height="120%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feColorMatrix
            type="matrix"
            values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
            in="SourceGraphic"
            result="colormatrix"
          />
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.09 1" />
            <feFuncG type="table" tableValues="0.07 1" />
            <feFuncB type="table" tableValues="0.76 1" />
            <feFuncA type="table" tableValues="0 1" />
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend" />
        </filter>
      </defs>
    </svg>
  )
}
