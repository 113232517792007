import React from "react"

import { Analytics, LegalEvents } from "@bounce/analytics"

import type { ContainerProps, ActionListProps, PrivacyPolicyLinkProps } from "../types"

const Container = ({ children }: ContainerProps) => {
  return (
    <div className="fixed top-0 z-max flex h-full w-full items-end justify-center bg-[black]/50 md:items-center">
      <div className="w-full rounded-lg bg-white px-5 pt-6 shadow-lg md:mx-6 md:max-w-sm">{children}</div>
    </div>
  )
}

const ActionList = ({ children }: ActionListProps) => (
  <div className="flex flex-col-reverse gap-y-4 py-4">{children}</div>
)

const PrivacyPolicyLink = ({ href, children }: PrivacyPolicyLinkProps) => (
  <a
    target="_blank"
    className="cursor-pointer text-primary"
    href={href}
    onClick={() => {
      Analytics.track(LegalEvents.PrivacyPolicyLinkPressed)
    }}>
    {children}
  </a>
)

export { Container, ActionList, PrivacyPolicyLink }
