import { AmplitudeExperimentEvents, Analytics } from "@bounce/analytics"
import Datadog from "@bounce/datadog"
import type { ValueOf } from "@bounce/util"

import type { AppSettings } from "../types"

type AnySetting = ValueOf<AppSettings>

type TrackExperimentArgs<TValue, TSetting extends string = AnySetting> = {
  setting: TSetting
  value: TValue
}

const cache: Record<string, string> = {}

/**
 * Returns true if the flag is an experiment flag (start with feature_).
 */
const isAnExperimentFlag = (setting: string): boolean => {
  if (setting.startsWith("feature_")) return true
  return false
}

/**
 * Check if the flag/value association is already in the cache.
 * Add it to the cache if it's not.
 * Returns true if the value is in the cache.
 */
const isInCache = <TValue, TSetting extends string = AnySetting>({
  setting,
  value,
}: TrackExperimentArgs<TValue, TSetting>) => {
  const stringifiedValue = JSON.stringify(value)
  const isInCache = cache[setting] === stringifiedValue
  if (isInCache) return true

  cache[setting] = stringifiedValue
  return false
}

/**
 *  Call Datadog.addFeatureFlagEvaluation and Analytics.track(AmplitudeExperimentEvents.Exposure, ...)
 *  only once per session, ignore non-experiment flags.
 *  Returns true if the experiment was tracked.
 */
export const trackExperimentOnce = <TValue, TSetting extends string = AnySetting>({
  setting,
  value,
}: TrackExperimentArgs<TValue, TSetting>): boolean => {
  if (!isAnExperimentFlag(setting) || isInCache({ setting, value })) return false

  Analytics.track(AmplitudeExperimentEvents.Exposure, {
    flag_key: setting,
    variant: String(value),
  })

  void Datadog.addFeatureFlagEvaluation(setting, value)
  return true
}
