import { createSelectorHooks } from "auto-zustand-selectors-hook"
import { create } from "zustand"
import type { StateCreator, StoreMutatorIdentifier } from "zustand"
import type { DevtoolsOptions } from "zustand/middleware"
import { devtools, createJSONStorage, persist } from "zustand/middleware"

import { Persistency } from "@bounce/persistency"

const IS_IN_TEST_MODE = process.env.NODE_ENV === "test"
const IS_IN_DEV_MODE = process.env.NODE_ENV === "development"

// eslint-disable-next-line deprecation/deprecation
const IS_IN_BROWSER = typeof navigator !== "undefined" && navigator.product !== "ReactNative"

type PersistOptions = {
  key: string
}

type CreateZustandStoreOptions<TStoreValue> = {
  initializer: StateCreator<TStoreValue>
  name: string
  devtoolsOptions?: DevtoolsOptions
  persistStore?: boolean | PersistOptions
}

type StoreMutators = [StoreMutatorIdentifier, unknown][]

export const createZustandStore = <TStoreValue extends Record<string, unknown>>({
  initializer,
  name,
  devtoolsOptions,
  persistStore,
}: CreateZustandStoreOptions<TStoreValue>) => {
  let store: StateCreator<TStoreValue, StoreMutators, StoreMutators> = initializer

  if (!IS_IN_TEST_MODE && IS_IN_BROWSER) {
    store = devtools(store, {
      name,
      serialize: true,
      enabled: true,
      trace: IS_IN_DEV_MODE,
      ...devtoolsOptions,
    })
  }

  if (persistStore) {
    store = persist(store, {
      name: typeof persistStore === "object" ? persistStore.key : name,
      storage: createJSONStorage(() => Persistency),
    })
  }

  return createSelectorHooks<TStoreValue>(create(store))
}
