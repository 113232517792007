import { useEffect } from "react"

import Datadog from "@bounce/datadog"

/**
 * Register a service worker to pre-cache the customer app.
 */
export const PreCacheServiceWorker = (): null => {
  const isSupportServiceWorker = "serviceWorker" in navigator

  useEffect(() => {
    if (!isSupportServiceWorker) return

    const loadServiceWorker = async () => {
      try {
        await navigator.serviceWorker.register("/sw.js", { scope: "/" })
      } catch (error) {
        void Datadog.addError(error)
      }
    }

    void loadServiceWorker()
  }, [isSupportServiceWorker])

  return null
}
