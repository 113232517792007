import type { AnalyticsBrowser } from "@segment/analytics-next"

import type { AnalyticsEvent, SegmentClient } from "../types"
import { AnalyticsEventTypes } from "../types"

export const clearEventsQueue = async (client: SegmentClient<AnalyticsBrowser>) => {
  if (!client.queuedEvents || !client.queuedEvents.length) return

  for (const event of client.queuedEvents) {
    const name = event.name ?? ""

    if (event.type === AnalyticsEventTypes.SCREEN) {
      continue
    }

    if (event.type === AnalyticsEventTypes.IDENTIFY) {
      client.queuedEvents = client.queuedEvents.filter(e => e !== event)
      await client.identify(event.properties?.userId, event.properties?.traits)
      return
    }

    if (event.type === AnalyticsEventTypes.PAGE && event.category) {
      const { category, properties } = event

      client.page(category, name, properties)
      continue
    }

    client.track(name, event.properties)
  }

  client.queuedEvents = []
}

export const pushEventToQueue = (
  client: SegmentClient<AnalyticsBrowser>,
  event: AnalyticsEvent,
  pushToStart?: boolean,
) => {
  if (!client.queuedEvents) client.queuedEvents = []

  if (pushToStart) {
    client.queuedEvents.unshift(event)
    return
  }

  client.queuedEvents.push(event)
}
