import isObject from "lodash/isObject"

import type { SessionContext } from "../types"

/**
 * Merge datadog session contexts
 * @example
 * mergeSessionContext({
 *   context: {
 *     application: {
 *       nativeApplicationVersion: "1.1.0",
 *     },
 *   },
 *   key: "application",
 *   value: {
 *     env: "production",
 *   },
 * })
 * // {
 * //  application: {
 * //    nativeApplicationVersion: "1.1.0",
 * //    env: "production",
 * //  },
 * //}
 */
export const mergeSessionContext = ({
  context,
  key,
  value,
}: {
  context: SessionContext
  key: keyof SessionContext
  value: SessionContext[keyof SessionContext]
}) => {
  if (isObject(context[key]) && isObject(value)) {
    context[key] = {
      ...context[key],
      ...value,
    }
    return context
  }

  context[key] = value
  return context
}
