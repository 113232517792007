import UAParser from "ua-parser-js"

import type { WebIntegration } from "../../types"

export const UserAgentIntegration: WebIntegration = {
  name: "User Agent",
  type: "before",
  version: "1.0.0",

  isLoaded: () => true,
  load: () => Promise.resolve(),
  identify: ctx => {
    ctx.event.traits = Object.assign({}, ctx.event.traits)
    try {
      const parser = new UAParser(window.navigator.userAgent)
      const parsedUA = parser.getResult()
      ctx.event.traits.browser_name = parsedUA.browser.name
      ctx.event.traits.browser_version = parsedUA.browser.version
      ctx.event.traits.user_agent = parsedUA.ua
    } catch (error) {
      // Unsupported/Blocked by the browser
    }

    return ctx
  },
}
