import CookiesConsentManager, { isInEUTimezone } from "@bounce/legal/web"

import { config } from "@/config"

const CookiesConsent = () => {
  return (
    !!config.segment.writeKey && (
      <CookiesConsentManager
        writeKey={config.segment.writeKey}
        config={config.segment}
        cookieAttributes={{ secure: process.env.NODE_ENV === "production" }}
        requiresConsent={() => isInEUTimezone()}
      />
    )
  )
}

export default CookiesConsent
