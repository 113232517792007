import { DEFAULT_config } from "./config.default"
import type { MarketingAppConfig } from "./config.types"
import { featureFlagOverrides } from "../libs/configcat/configCat"

const domain = process.env.NEXT_PUBLIC_BOUNCE_DOMAIN || "bounce.com"

export const PRODUCTION_config: MarketingAppConfig = {
  ...DEFAULT_config,
  id: "PRODUCTION",
  domain,
  cookieDomain: domain,
  devMode: false,
  apis: {
    marketing: `https://${domain}`,
    bounce: "https://graphql.usebounce.com",
    packageAcceptance: "https://package-acceptance-api.usebounce.com",
  },
  apps: {
    packages: `https://${domain}`,
    customer: `https://${domain}`,
    marketing: `https://${domain}`,
    partner: `https://partner.${domain}`,
  },
  intercom: {
    luggageStorageAppId: "x85uh933",
    packagesAppId: "x85uh933",
    partnerAppId: "moqwm5ew",
  },
  datadog: {
    ...DEFAULT_config.datadog,
    env: "prod",
    enabled: true,
    tracingOrigins: ["https://graphql.usebounce.com"],
    version: DEFAULT_config.commitSha?.slice(0, 6),
    sampleRate: 50,
  },
  segment: {
    writeKey: "KeG8C906eJRXss3Qe2kkfR77xWIVMHPf",
    cdnURL: `https://seg-cdn.${domain}`,
    proxy: `https://seg-api.${domain}/v1`,
    debug: false,
    logger: false,
  },
  configCat: {
    key: "1bDZCOyoD0WGGJ9ql6GYzw/0wVDfC4ESEiobtrcny0IZA",
    flagOverrides: featureFlagOverrides,
  },
  ashby: {
    key: process.env.ASHBY_KEY,
  },
  featureFlags: {
    ...DEFAULT_config.featureFlags,
    isAutoExposureTrackingEnabled: true,
  },
}
