export type BaseAppConfig = {
  id: "USER" | "PREVIEW" | "PRODUCTION" | "DEFAULT"
  devMode: boolean
  protocol: "https" | "http"
  domain: string
  cookieDomain: string | null
  prLinks: string
  commitSha: string | undefined
}

export type AppUrlsConfig = {
  apps: {
    packages: string
    customer: string
    partner: string
    marketing: string
  }
}

/**
 * Gets the configuration based on the provided environment and hostname.
 * If the hostname includes preview.usebounce.com, the preview config is returned.
 */
export const getConfig = <TAppConfig = BaseAppConfig>({
  env,
  hostname,
  isE2ETest,
  configs: { production, preview, user },
}: {
  env: string | undefined
  hostname: string | undefined
  configs: {
    production: TAppConfig
    preview: TAppConfig
    user?: TAppConfig
  }
  isE2ETest?: boolean
}): TAppConfig => {
  const isPreviewDomain = hostname?.includes("preview.usebounce.com") || hostname?.includes("preview.bounce.com")
  if (isPreviewDomain || isE2ETest) {
    return preview
  }

  // If env is not defined in a deployed app, it's safer to fall back to production
  const defaultConfig = process.env.NODE_ENV === "production" ? production : preview

  switch (env) {
    case "PRODUCTION":
      return production

    case "PREVIEW":
      return preview

    default:
      return user || defaultConfig
  }
}

/**
 * Calculates the Vercel preview URL based on the branch and project name.
 * https://vercel.com/docs/deployments/generated-urls#truncation
 * @param branch
 * @param projectName
 */
export async function vercelPreviewUrl(branch: string, projectName: string): Promise<string> {
  const baseString = `${projectName}-git-${branch}`
  const maxLength = 56
  const truncatedString = baseString.length > maxLength ? baseString.slice(0, maxLength) : baseString
  const hashSuffix = await computeHash({ prefix: "git-", branch, projectName })
  const hostname = `${truncatedString}-${hashSuffix}.vercel.usebounce.io`.replace("/", "-")
  const url = `https://${hostname}`

  return url
}

type ComputeHashOptions = {
  prefix: string
  branch: string
  projectName: string
}

async function computeHash({ prefix, branch, projectName }: ComputeHashOptions): Promise<string> {
  const encoder = new TextEncoder()
  const data = encoder.encode(`${prefix}${branch}${projectName}`)
  const hashBuffer = await crypto.subtle.digest("SHA-256", data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("")
  return hashHex.slice(0, 6)
}
