import i18next from "i18next"

import { LOCALES } from "../constants/locales"
import type { Locale, LocaleData, Locales } from "../types"

/**
 * @example
 * getCurrentLocale() // { "locale": "en-US", "language": "en", ... }
 */
export const getCurrentLocale = (): Locale => {
  const key = i18next.language as Locales

  return LOCALES[key] as Locale
}

/**
 * @example
 * getLocale("language", "en") // { "locale": "en-US", "language": "en", ... }
 */
export const getLocale = (key: keyof Locale, value: string, ignoreCase: boolean = true): Locale | undefined => {
  return Object.values(LOCALES).find(locale =>
    typeof locale[key] === "string" && ignoreCase
      ? (locale[key] as string).toUpperCase() === value.toUpperCase()
      : locale[key] === value,
  ) as Locale | undefined
}

/**
 * @example
 * getLocaleData("en-US") // { "locale": "en-US", "language": "en", ... }
 */
export const getLocaleData = (locale: Locales) => LOCALES[locale]

/**
 * @example
 * getLocalesData(["en-US", fr-FR"]) // {"en-US": { "language": "en", ... }, "fr-FR": { "language": "fr", ... }}
 */
export const getLocalesData = (locales?: Locales[]): LocaleData => {
  if (!locales) return LOCALES as LocaleData
  return locales.reduce((acc, locale) => ({ ...acc, [locale]: LOCALES[locale] }), {})
}

export const excludeLocales = (locales: Locales[]): Locales[] => {
  return Object.keys(LOCALES).filter(locale => !locales.includes(locale as Locales)) as Locales[]
}

export const localeDataAsOptions = (locales: LocaleData) =>
  Object.values(locales)
    .filter(locale => locale.active)
    .map(({ locale, friendlyName, emoji }) => ({
      id: locale,
      value: locale,
      label: friendlyName,
      emoji,
    }))
