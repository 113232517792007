import classNames from "classnames"
import * as React from "react"

import { useIsClient } from "../../../hooks/useIsClient"

export type PreviewBannerProps = {
  href: string
  prNumber?: string
  className?: string
}

export const PreviewBanner = ({ prNumber, href, className }: PreviewBannerProps) => {
  const isClient = useIsClient()

  if (!isClient) return null

  return (
    <a
      href={href}
      target="_blank"
      className={classNames(
        "fixed right-[5%] top-0 z-[100] flex flex-row items-center justify-center rounded-b-lg bg-primary px-4 py-1",
        className,
      )}>
      <span className="text-xs font-bold text-white">Preview Version</span>
      {!!prNumber && <span className="ml-1 text-xs text-white">(PR #{prNumber})</span>}
    </a>
  )
}
