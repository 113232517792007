import type { Plugin as WebIntegration, Context as WebContext, JSONObject } from "@segment/analytics-next"
import type { DestinationPlugin } from "@segment/analytics-react-native"

export type EventProperties = {
  [key: string]: any
  [index: number]: never
}

export type { WebIntegration, WebContext }

export type SegmentClientConfig = {
  writeKey: string | null | undefined
  debug: boolean
  logger: boolean
  integrationsToLoad?: Record<string, boolean | JSONObject>
  facebookAppEvents?: boolean
  anonymousId?: string
  // Web
  webIntegrations?: WebIntegration[]
  cdnURL?: string
  // Native
  nativeIntegrations?: DestinationPlugin[]
  proxy?: string
  trackAppLifecycleEvents?: boolean
  trackDeepLinks?: boolean
  collectDeviceId?: boolean
}

export enum AnalyticsEventTypes {
  PAGE = "page",
  SCREEN = "screen",
  TRACK = "track",
  IDENTIFY = "identify",
}

export type AnalyticsEvent = {
  name?: string
  category?: string
  type: AnalyticsEventTypes
  properties?: EventProperties
}

export type SegmentClient<TClient = undefined> = SegmentIntegration & {
  client?: TClient | undefined
  queuedEvents?: AnalyticsEvent[]
  getAnonymousId: () => Promise<string>
  setAnonymousId: (id: string) => Promise<void>
  isSetup: () => boolean
  setup: (config: SegmentClientConfig) => Promise<void>
}

export type SegmentIntegration = {
  track: (event: string, properties?: EventProperties) => void
  /**
   * @description
   * With an nullable userId, add properties to the current user (authenticated or not)
   *
   * use cases:
   *  - add properties to a user without having access to the user id
   *  - add properties to an unauthenticated user
   *
   * @example
   * Analytics.identify(undefined, {
   *  property_1: "value",
   *  property_2: "value",
   * })
   *
   * @description
   * With an userId, link the current session to a user, and add properties.
   * Identify with an userId allow to track the users across the sessions and devices
   *
   * use cases:
   *  - link the current session to a user, when the user logged in
   *
   * @example
   * Analytics.identify("user-id", {
   *  property_1: "value",
   *  property_2: "value",
   * })
   */
  identify: (userId: string | undefined, traits?: EventProperties) => Promise<void>
  reset: () => Promise<void>
  // Native
  screen: (name: string) => void
  // Web
  page: (category: string, name: string, properties?: EventProperties) => void
}

export type AnalyticsAppConfig = {
  segment: SegmentClientConfig
}
