import previewConfig from "./config.preview.json"
import type { FeatureFlagOverrides } from "../../config"

// Load the configcat static config file.
export let featureFlagOverrides: FeatureFlagOverrides
try {
  featureFlagOverrides = require("./config.json")
} catch (error) {
  featureFlagOverrides = previewConfig
}
