import type { CategoryPreferences } from "@segment/consent-manager/types/types"
import type { ZustandHookSelectors } from "auto-zustand-selectors-hook"
import { createSelectorHooks } from "auto-zustand-selectors-hook"
import type { StateCreator, StoreMutatorIdentifier } from "zustand"
import { create } from "zustand"
import { persist, createJSONStorage, devtools } from "zustand/middleware"

import { Persistency } from "@bounce/persistency"

import type { CookiePreferencesBanner } from "../helpers/types"

interface CookiePreferencesState {
  currentDialog: CookiePreferencesBanner
  preferences: CategoryPreferences | undefined
  destinationPreferences: CategoryPreferences | undefined
  setCurrentDialog: (dialog: CookiePreferencesBanner) => void
  setPreferences(preferences: CategoryPreferences): void
  setDestinationPreferences(destinationPreferences: CategoryPreferences): void
  openPreferences: () => void
}

// eslint-disable-next-line deprecation/deprecation
const isWeb = typeof navigator !== "undefined" && navigator.product !== "ReactNative"
const initialPreferences = !isWeb
  ? {
      functional: true,
      marketingAndAnalytics: true,
      advertising: true,
    }
  : undefined

type StoreMutators = [StoreMutatorIdentifier, unknown][]
type CookiePreferencesStateCreator = StateCreator<CookiePreferencesState, StoreMutators, StoreMutators>

let store: CookiePreferencesStateCreator = persist(
  set => ({
    currentDialog: false,
    preferences: initialPreferences,
    destinationPreferences: undefined,
    openPreferences: () => set({ currentDialog: "preferences" }),
    setCurrentDialog: dialog => set({ currentDialog: dialog }),
    setPreferences: preferences => set({ preferences }),
    setDestinationPreferences: destinationPreferences => set({ destinationPreferences }),
  }),
  {
    name: "cookie-preferences",
    storage: createJSONStorage(() => Persistency),
  },
)

if (isWeb) {
  store = devtools(store, {
    name: "useCookiePreferences",
    serialize: true,
    enabled: true,
  })
}

export const _useCookiePreferences = create(store)

export const useCookiePreferences = createSelectorHooks(_useCookiePreferences) as typeof _useCookiePreferences &
  ZustandHookSelectors<CookiePreferencesState>
