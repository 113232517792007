import React from "react"

import { Button, Switch } from "@bounce/web-components"

import type { CookiePreferenceActions } from "../../helpers/types"
import type { CategoryListProps, CategoryProps, HeaderProps } from "../types"

const Actions = ({ accept, save }: CookiePreferenceActions) => {
  return (
    <>
      <Button as="button" size="sm" onClick={save.handler} variant="ghost" className="w-full !text-black">
        {save.text}
      </Button>
      <Button as="button" size="sm" onClick={accept.handler} className="w-full" color="primary">
        {accept.text}
      </Button>
    </>
  )
}

const Header = ({ title, children }: HeaderProps) => {
  return (
    <div className="pb-4 text-left">
      <h5 className="title3">{title}</h5>
      <p className="paragraph1 mt-2 text-neutral-800">{children}</p>
    </div>
  )
}

const Category = ({ text, checked, onClick, alwaysActive }: CategoryProps) => {
  if (!text) return null
  return (
    <div className="flex items-center justify-between border-b border-b-neutral-200">
      <div className="paragraph1 py-4 text-left">{text}</div>
      {!!alwaysActive && <div className="paragraph1 text-neutral-800">{alwaysActive}</div>}
      {!alwaysActive && <Switch active={!!checked} onClick={onClick} />}
    </div>
  )
}

const CategoryList = ({ children }: CategoryListProps) => {
  return <div className="py-2">{children}</div>
}

export { Actions, Header, Category, CategoryList }
