const BackwardsCompatibilityPostProcessor = require("./src/libs/i18n/BackwardsCompatibilityPostProcessor")

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  debug: false,
  i18n: {
    locales: ["en", "es", "fr", "de", "it", "pt", "ja", "ko", "zh", "ru", "pl", "nl", "cs", "da", "hu"],
    defaultLocale: "en",
  },
  defaultNS: "marketing",
  fallbackLng: {
    en: ["en-US"],
    es: ["es-ES"],
    fr: ["fr-FR"],
    de: ["de-DE"],
    it: ["it-IT"],
    pt: ["pt-PT"],
    ja: ["ja-JP"],
    ko: ["ko-KR"],
    zh: ["zh-CN"],
    ru: ["ru-RU"],
    pl: ["pl-PL"],
    nl: ["nl-NL"],
    cs: ["cs-CZ"],
    da: ["da-DK"],
    hu: ["hu-HU"],
  },
  ns: [
    "marketing",
    "marketing-generic",
    "marketing-ls-app",
    "marketing-ls-cities",
    "marketing-ls-city",
    "marketing-ls-event",
    "marketing-ls-home",
    "marketing-ls-poi",
    "marketing-ls-store",
    "marketing-pa",
    "marketing-partner",
    "store",
    "package-acceptance",
    "legal",
  ],
  localePath: typeof window === "undefined" ? require("path").resolve("./public/locales") : "/locales",
  reloadOnPrerender: process.env.NODE_ENV === "development",
  serializeConfig: false,
  use: [require("i18next-icu"), BackwardsCompatibilityPostProcessor],
  postProcess: [BackwardsCompatibilityPostProcessor.name],
}
