/**
 * This is in a JS file so that it can be imported anywhere,
 * including next-i18next.config.js on the marketing app.
 * @type {import("i18next").PostProcessorModule}
 */
const BackwardsCompatibilityPostProcessor = {
  type: "postProcessor",
  name: "BackwardsCompatibility",
  process(value, key, options) {
    // If the translated value still includes curly braces, it means it still has placeholders in it
    if (!value.includes("{")) {
      return value
    }

    return Object.keys(options).reduce((acc, optionKey) => {
      return acc.replace(new RegExp(`\\{\\{\\s*${optionKey}\\s*\\}\\}`, "g"), options[optionKey])
    }, value)
  },
}

module.exports = BackwardsCompatibilityPostProcessor
