import classNames from "classnames"
import React from "react"

export type SwitchProps = {
  active: boolean
  onClick?: () => void
}

const Switch = ({ active = false, onClick }: SwitchProps) => {
  return (
    <div className={classNames("switch", active ? "switch--active" : "switch--idle")} onClick={onClick}>
      <div className={classNames("switch__bubble", active ? "switch__bubble--active" : "switch__bubble--idle")} />
    </div>
  )
}

export default Switch
