import React, { useCallback } from "react"

import Datadog from "../Datadog"
import type { UseDatadogHook } from "../types"

const useDatadog: UseDatadogHook = options => {
  const [loaded, setLoaded] = React.useState(false)

  const loadDatadog = useCallback(
    ({ defer }: { defer: boolean }) => {
      if (loaded) return

      const load = () => {
        Datadog.init(options)
        setLoaded(true)
      }

      if (defer) {
        window.addEventListener("scroll", load, { once: true })
        return
      }

      load()
    },
    [loaded, options],
  )

  return { loaded, load: loadDatadog }
}

export default useDatadog
