import type { ReactNode } from "react"
import { useEffect, useState } from "react"

import { Persistency } from "@bounce/persistency"

import ReferralContext from "./ReferralsContext"
import type { ReferralCodes } from "./types"

export type UrlParams = {
  coupon?: string | string[]
  couponCode?: string | string[]
  ref?: string | string[]
  referralCode?: string | string[]
  rwg_token?: string
  utm_medium?: string
  utm_campaign?: string
}

export type ReferralsProviderProps = {
  urlParams: UrlParams
  children?: ReactNode
}

export const NEW_ON_BOUNCE_DISCOUNT_CODE = "NEWONBOUNCE"
const BUY_4_GET_1_DISCOUNT_CODE = "BUY4GET1"
const WELCOME_DISCOUNT_CODE = "WELCOME10"
const AUTO_APPLIED_COUPONS = [NEW_ON_BOUNCE_DISCOUNT_CODE, BUY_4_GET_1_DISCOUNT_CODE, WELCOME_DISCOUNT_CODE]

const getUrlParam = (
  urlParams: UrlParams,
  key: "coupon" | "couponCode" | "ref" | "referralCode" | "rwg_token" | "utm_campaign" | "utm_medium",
): string | undefined => {
  if (urlParams[key]) {
    return Array.isArray(urlParams[key]) ? urlParams[key]![0] : (urlParams[key] as string)
  }
  return undefined
}

const getCodesFromParams = (urlParams: UrlParams): ReferralCodes => {
  return {
    coupon: getUrlParam(urlParams, "coupon") ?? getUrlParam(urlParams, "couponCode"),
    referral: getUrlParam(urlParams, "ref") ?? getUrlParam(urlParams, "referralCode"),
    rwgToken: getUrlParam(urlParams, "rwg_token"),
  }
}

const setReferralCookie = (key: string, value: string) =>
  Persistency.setCookie({
    key,
    value,
    expires: new Date(Date.now() + 30 * 86400 * 1000),
    path: "/",
  })

const persist = async (codes: ReferralCodes, previousCodes: ReferralCodes) => {
  if (codes.coupon) {
    if (AUTO_APPLIED_COUPONS.includes(codes.coupon)) return

    await Persistency.setCookie({ key: "coupon", value: codes.coupon, path: "/" })
  }

  if (codes.referral && previousCodes.referral === undefined) {
    // Persist for 30 Days from 1st visit and have it not be overriden by the next visit
    await setReferralCookie("referral", codes.referral)
  }
  if (codes.rwgToken && previousCodes.rwgToken === undefined) {
    // Persist for 30 Days from 1st visit and have it not be overriden by the next visit
    await setReferralCookie("rwg_token", codes.rwgToken)
  }
}

export const getPersistedCodes = async (): Promise<ReferralCodes> => {
  const persistedCouponCode = await Persistency.getCookie("coupon")
  const persistedReferralCode = await Persistency.getCookie("referral")
  const persistedRwgToken = await Persistency.getCookie("rwg_token")
  return {
    referral: persistedReferralCode ?? undefined,
    coupon: persistedCouponCode ?? undefined,
    rwgToken: persistedRwgToken ?? undefined,
  }
}

// Prioritize walk-ins referrals over persisted ones.
// Prioritize persisted ones over referrals from params (when not a walk-in use-case)
const getPrioritizedReferralCode = (
  urlCodes: ReferralCodes,
  persistedCodes: ReferralCodes,
  urlParams: UrlParams,
): string | undefined => {
  // https://www.notion.so/usebounce/UTM-Parameters-8e3c98a432f94497adb8757648799b8d
  const isWalkIn =
    urlParams.utm_campaign === "signage" ||
    urlParams.utm_campaign === "partner_qr_code_booking" ||
    (urlParams.utm_campaign === "affiliate" && urlParams.utm_medium === "qrcode")

  // Override persisted referral code if it's a walk-in and use the code from the url params
  if (isWalkIn) {
    return urlCodes.referral
  }

  return persistedCodes.referral ?? urlCodes.referral
}

const mergeCodes = (urlCodes: ReferralCodes, persistedCodes: ReferralCodes, urlParams: UrlParams): ReferralCodes => {
  return {
    referral: getPrioritizedReferralCode(urlCodes, persistedCodes, urlParams),
    coupon: urlCodes.coupon ?? persistedCodes.coupon,
    rwgToken: persistedCodes.rwgToken ?? urlCodes.rwgToken, // Rwg token should be sticky on 1st visit
  }
}

const getCodes = async (urlParams: UrlParams): Promise<ReferralCodes> => {
  const persistedCodes = await getPersistedCodes()
  const urlCodes = getCodesFromParams(urlParams)

  const mergedCodes = mergeCodes(urlCodes, persistedCodes, urlParams)

  await persist(mergedCodes, persistedCodes)

  return mergedCodes
}

const ReferralsProvider = ({ urlParams, children }: ReferralsProviderProps) => {
  const [codes, setCodes] = useState<ReferralCodes>({})

  useEffect(() => {
    getCodes(urlParams)
      .then(setCodes)
      .catch(() => {})
  }, [urlParams])

  return <ReferralContext.Provider value={codes}>{children}</ReferralContext.Provider>
}

export default ReferralsProvider
