import { DEFAULT_config } from "./config.default"
import type { MarketingAppConfig } from "./config.types"

const isServer = typeof window === "undefined"

export const PREVIEW_config: MarketingAppConfig = {
  ...DEFAULT_config,
  id: "PREVIEW",
  apis: {
    ...DEFAULT_config.apis,
    bounce:
      isServer || process.env.VERCEL_GIT_PULL_REQUEST_ID === undefined
        ? DEFAULT_config.apis.bounce
        : `https://luggage-storage-api-pr-${process.env.VERCEL_GIT_PULL_REQUEST_ID}.onrender.com`,
    packageAcceptance:
      isServer || process.env.VERCEL_GIT_PULL_REQUEST_ID === undefined
        ? DEFAULT_config.apis.bounce
        : `https://package-acceptance-api-pr-${process.env.VERCEL_GIT_PULL_REQUEST_ID}.onrender.com`,
  },
}

export let USER_config: MarketingAppConfig | undefined
