import { useEffect, useRef } from "react"

import type { Nullable } from "../types"

/**
 * Maintains the `onSuccess`/`onError` behavior the react-query `useQuery` hook
 * used to have by only running the callback when the value changes. You don't
 * have to worry about managing a list of `useEffect` dependencies.
 */
export const useOnQueryResult = <TDataOrError>(
  dataOrError: Nullable<TDataOrError>,
  onResult: (dataOrError: TDataOrError) => void,
) => {
  const onResultRef = useRef(onResult)
  onResultRef.current = onResult

  useEffect(() => {
    if (dataOrError === undefined || dataOrError === null) return
    onResultRef.current(dataOrError)
  }, [dataOrError])
}
