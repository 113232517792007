import { log } from "../../helpers/logger"
import type { WebIntegration } from "../../types"

const LoggerIntegration: WebIntegration = {
  name: "Logger",
  type: "before",
  version: "1.0.0",

  isLoaded: () => true,
  load: () => Promise.resolve(),
  track: ctx => {
    log(ctx.event.type, ctx.event)

    return ctx
  },
  identify: ctx => {
    log(ctx.event.type, ctx.event)

    return ctx
  },
  page: ctx => {
    log(ctx.event.type, ctx.event)

    return ctx
  },
  alias: ctx => {
    log(ctx.event.type, ctx.event)

    return ctx
  },
  group: ctx => {
    log(ctx.event.type, ctx.event)

    return ctx
  },
  screen: ctx => {
    log(ctx.event.type, ctx.event)

    return ctx
  },
}

export { LoggerIntegration }
