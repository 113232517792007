type Platform = "ios" | "android" | "other"

/**
 * Return true if the user is a bot
 */
export const isABot = () => {
  // tester- is used as a prefix for the user agent on our tests (Datadog Syntethics)
  // The list of the bots https://udger.com/resources/ua-list/crawlers
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (
    typeof navigator !== "undefined" && /tester-|bot|crawler|spider|crawling|HeadlessChrome/i.test(navigator.userAgent)
  )
}

/**
 * Return device's OS, based on user agent (only for mobile web)
 */
export const getMobileWebOS = (): Platform => {
  if (typeof navigator === "undefined") return "other"

  const ua = navigator.userAgent

  if (/android/i.test(ua)) {
    return "android"
  }

  if (/iPad|iPhone|iPod/.test(ua)) {
    return "ios"
  }

  return "other"
}
