import classNames from "classnames"
import * as React from "react"

export type ButtonVariants = "primary" | "secondary" | "ghost"
export type ButtonColors = "primary" | "coral" | "white"
export type ButtonSizes = "md" | "sm"

type ButtonOwnProps<E extends React.ElementType = React.ElementType> = {
  as?: E
  variant?: ButtonVariants
  color?: ButtonColors
  size?: ButtonSizes
  className?: string
  iconClassName?: string
  textClassName?: string
  children: string
  disabled?: boolean
  LeftIcon?: React.FC<{ className: string }>
  RightIcon?: React.FC<{ className: string }>
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined
}

export type ButtonProps<E extends React.ElementType> = ButtonOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof ButtonOwnProps>

export const variants: Record<ButtonVariants, Record<ButtonColors, string>> = {
  primary: {
    primary: "btn--primary-primary",
    coral: "btn--primary-coral",
    white: "btn--primary-white",
  },
  secondary: {
    primary: "btn--secondary-primary",
    coral: "btn--secondary-coral",
    white: "btn--secondary-white",
  },
  ghost: {
    primary: "btn--ghost-primary",
    coral: "btn--ghost-coral",
    white: "btn--ghost-white",
  },
}

const sizes: Record<ButtonSizes, Record<"container" | "icon", string>> = {
  sm: {
    container: "btn--sm",
    icon: "btn__icon--sm",
  },
  md: {
    container: "btn--md",
    icon: "btn__icon--md",
  },
}

const __DEFAULT_TAB_ELEMENT__ = "a"

export const Button = <E extends React.ElementType = typeof __DEFAULT_TAB_ELEMENT__>({
  children,
  as,
  variant = "primary",
  color = "primary",
  size = "md",
  className = "",
  iconClassName,
  textClassName,
  disabled,
  LeftIcon,
  RightIcon,
  ...props
}: ButtonProps<E>) => {
  const Component = as || __DEFAULT_TAB_ELEMENT__
  const variantClassNames = variants[variant][color]
  const sizeClassNames = sizes[size]

  return (
    <Component
      {...props}
      disabled={disabled}
      className={classNames("btn", sizeClassNames.container, variantClassNames, disabled && "opacity-30", className)}>
      {LeftIcon && <LeftIcon className={classNames(iconClassName, sizeClassNames.icon)} />}
      {!!children && <span className={textClassName}>{children}</span>}
      {RightIcon && <RightIcon className={classNames(iconClassName, sizeClassNames.icon)} />}
    </Component>
  )
}
