/**
 *
 * Segment plugin that adds a session id to Segment events, to be used by Amplitude.
 * This plugin is based on the AmplitudeSessionPlugin from the Segment SDK.
 * We added an indicator for the first event of the session, that we can use to reset the campaign data before sending the event to Amplitude.
 *
 */

import type { Analytics, Context, Plugin } from "@segment/analytics-next"

const DEFAULT_SESSION_LENGTH_IN_MS = 1800000 // 30 minutes

export const amplitudeSessionPlugin = () => {
  let active: boolean
  let sessionId: number | undefined
  let isFirstEventOfSession: boolean | undefined

  // Load the plugin and activate session management

  const identity: Plugin = {
    name: "Actions Amplitude",
    type: "enrichment",
    version: "1.0.0",

    load: async (_ctx: Context, _ajs: Analytics) => {
      active = true
      return Promise.resolve()
    },

    isLoaded: () => {
      return active
    },

    track: (ctx: Context) => {
      if (!active) return ctx
      return execute(ctx)
    },

    identify: (ctx: Context) => {
      if (!active) return ctx
      return execute(ctx)
    },

    page: (ctx: Context) => {
      if (!active) return ctx
      return execute(ctx)
    },

    group: (ctx: Context) => {
      if (!active) return ctx
      return execute(ctx)
    },

    alias: (ctx: Context) => {
      if (!active) return ctx
      return execute(ctx)
    },
  }

  const execute = (ctx: Context) => {
    refreshSession()

    const result = ctx

    result.event.integrations = {
      ...result.event.integrations,
      ...(sessionId && isFirstEventOfSession !== undefined
        ? { [identity.name]: { session_id: sessionId, is_first_event_of_session: isFirstEventOfSession } }
        : {}),
    }

    isFirstEventOfSession = false

    return result
  }

  // Reset session by generating a new session ID and setting the first event flag
  const resetSession = () => {
    sessionId = Date.now()
    isFirstEventOfSession = true

    // Save session data to localStorage
    window.localStorage.setItem("analytics_session_id", sessionId.toString())
    window.localStorage.setItem("analytics_session_id.last_access", Date.now().toString())
  }

  // Refresh session based on localStorage or create a new session if it's stale
  const refreshSession = (sessionLength: number = DEFAULT_SESSION_LENGTH_IN_MS) => {
    const storedSessionId = window.localStorage.getItem("analytics_session_id")
    const lastAccess = window.localStorage.getItem("analytics_session_id.last_access")
    const currentTime = Date.now()

    if (!storedSessionId || !lastAccess || isSessionStale(lastAccess, sessionLength)) {
      // Create a new session if no session exists or it's stale
      resetSession()
    } else {
      // Continue the existing session
      sessionId = parseInt(storedSessionId, 10)
      isFirstEventOfSession = false
      window.localStorage.setItem("analytics_session_id.last_access", currentTime.toString())
    }
  }

  // Check if the session is stale (based on session length)
  const isSessionStale = (lastAccess: string, sessionLength: number): boolean => {
    const lastAccessTime = parseInt(lastAccess, 10)
    const currentTime = Date.now()

    return currentTime - lastAccessTime >= sessionLength
  }

  return identity
}
