import { useQuery } from "@tanstack/react-query"
import type { User } from "configcat-react"
import { useConfigCatClient } from "configcat-react"

import { AmplitudeExperimentEvents, Analytics } from "@bounce/analytics"
import { notEmpty, useOnQueryResult } from "@bounce/util"
import type { MakeOptional } from "@bounce/util"

import { CommonFeatureFlag } from "../flags"
import type { UserMatchers } from "../types"

export type FeatureFlags = {
  [k: string]: unknown
}

type UseFeatureFlagArgs = MakeOptional<UserMatchers, "custom"> & {
  enums: {
    flags: Record<string, string>
    config: Record<string, string>
  }
}

/**
 *
 * Load all used feature flags
 * @example
 * const { featureFlags, error, isLoading } = useFeatureFlags({
 *   id: "123e4567-e89b-12d3-a456-426614174000",
 *   email: "code@usebounce.com",
 * })
 *
 * if (featureFlags?.feature_console_log_hello) {
 *   console.log("hello")
 * }
 */
export const useFeatureFlags = ({ id, enums, ...userMatchers }: UseFeatureFlagArgs) => {
  const configCatClient = useConfigCatClient()

  const {
    isLoading,
    data: featureFlags,
    error,
  } = useQuery<FeatureFlags>({
    queryKey: ["ConfigCat", "ALL_FLAGS", id, userMatchers, enums],
    queryFn: async () => {
      const userDetails: User = {
        country: userMatchers.country || undefined,
        email: userMatchers.email || undefined,
        identifier: id,
        custom: {},
      }
      Object.entries(userMatchers.custom || {}).forEach(([key, value]) => {
        if (notEmpty(value)) {
          userDetails.custom[key] = value
        }
      })

      const settings = await configCatClient.getAllValuesAsync(userDetails)
      const allSupportedSettings = Object.values<string>({ ...enums.flags, ...enums.config, ...CommonFeatureFlag })

      return Object.fromEntries(
        settings
          .filter(setting => allSupportedSettings.includes(setting.settingKey))
          .map(obj => [obj.settingKey, obj.settingValue]),
      )
    },
    notifyOnChangeProps: ["data"],
  })

  useOnQueryResult(featureFlags, flags => {
    const properties = Object.entries(flags).map(([key, value]) => {
      return [`[Experiment] ${key}.variant`, String(value)]
    })

    Analytics.track(AmplitudeExperimentEvents.Assignment, Object.fromEntries(properties))
  })

  return { isLoading, featureFlags, error }
}
