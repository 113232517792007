const getDeviceWidthType = (width: number): string => {
  const isMobile = width < 768
  const isTablet = width >= 768 && width < 1024

  if (isMobile) return "Mobile"
  if (isTablet) return "Tablet"
  return "Desktop"
}

const getPlatformProperties = (app_name: string) => {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window
  const { width: screenWidth, height: screenHeight } = screen
  const screenType = getDeviceWidthType(screenWidth)

  return {
    app_name,
    screenHeight,
    screenWidth,
    screenType,
    windowHeight,
    windowWidth,
  }
}

export { getPlatformProperties }
