/**
 * Ecommerce events
 * @see https://segment.com/docs/connections/spec/ecommerce/v2/
 */
export enum AuthEvents {
  LoggedIn = "Logged In",
  LoggedOut = "Logged Out",
  TriggeredFallbackSmsOTP = "Triggered Fallback OTP (SMS)",
  ChangedPhoneCountryCode = "Changed Phone Country Code",
  // Verify
  VerifyShown = "Verify Shown",
  VerifySkipped = "Verify Skipped",
  VerifySentCode = "Verify Sent Code",
  VerifyCompleted = "Verify Completed",
  // Social Login
  SocialLoginFailed = "Social Login Failed",
}

export enum LocationEvents {
  LocationsFiltered = "Locations Filtered",
  LocationsSearched = "Locations Searched",
  LocationsRetrieved = "Locations Retrieved",
  AskedForGeolocationPermissions = "Asked For Geolocation Permissions",
  ReadPermissionsTimeout = "Read Geolocation Permissions Timeout",
  RequestPermissionsTimeout = "Request Geolocation Permissions Timeout",
}

export enum OrderEvents {
  ProductViewed = "Product Viewed",
  CustomerProfileEntered = "Customer Profile Completed",
  PaymentInfoEntered = "Payment Info Entered",
  PaymentInfoEnteredFailed = "Payment Info Entered Failed",
  SupportedPaymentsDetected = "Supported Payments Detected",
  PaymentCTAShown = "Payment CTA Shown",
  ChangePaymentMethodButtonPressed = "Change Payment Method Button Pressed",
  ChangedPreferedPayment = "Changed Prefered Payment",
  OrderCompleted = "Order Completed",
  OrderUpdated = "Order Updated",
  OrderCancelled = "Order Cancelled",
  BookingFailed = "Booking Failed",
  TippedStore = "Tipped Store",
  TipSelected = "Tip Selected",
  PromotionAvailableShown = "Promotion Available Shown",
  // Lockers
  LockerDoorsSelected = "Locker Doors Selected",
  LockerDoorsUnavailable = "Locker Doors Unavailable",
}

export enum ReviewEvents {
  ReviewsSortBy = "Sort Reviews By",
  ToggleReviewTranslations = "Toggle Review Translations",
  LoadMoreReviews = "Load more on Reviews List",
  ReviewCompleted = "Review Completed",
  BadFeedbackGiven = "Bad Feedback Given",
  BadFeedbackSkipped = "Bad Feedback Skipped",
  ReviewRatingSelected = "Review Rating Selected",
  ReviewReasonSelected = "Review Reason Selected",
  SubmitReviewPressed = "Pressed Submit Review",
  ReviewViewed = "Review Viewed",
}

export enum CouponEvents {
  CouponEntered = "Coupon Entered",
  CouponApplied = "Coupon Applied",
  CouponDenied = "Coupon Denied",
  CouponRemoved = "Coupon Removed",
  CouponBannerShown = "Coupon Banner Shown",
}

/**
 * General events
 */
export enum AppStateEvents {
  LoadedSegment = "Loaded Segment",
}

export enum AppInteractionEvents {
  PushNotificationOpened = "Push Notification Opened",
  LocaleChanged = "Locale Changed",
}

export enum AppUpdateEvents {
  AppUpdateAlertDisplayed = "App Update Alert Displayed",
  AppUpdateAlertDismiss = "App Update Alert Dismiss",
  AppUpdateAlertUpdatePressed = "App Update Alert Update Pressed",
}

export enum ErrorEvents {
  ErrorBoundary = "Error Boundary",
  ErrorBoundaryForceAppUpdate = "Error Boundary Force App Update",
}

export enum AmplitudeExperimentEvents {
  Assignment = "[Experiment] Assignment",
  /** See https://amplitude.com/docs/experiment/under-the-hood/event-tracking#exposure-events */
  Exposure = "$exposure",
}

/**
 * App events
 */
export enum SharedInteractionEvents {
  EnterChat = "Enter Chat",
  TranslateConversationPressed = "Translate Conversation CTA Pressed",
  // Share Button on Reservation Page
  ShareReservationButtonPressed = "Share Reservation Button Pressed",
  ReservationShared = "Reservation Shared",
  SharedReservationVisited = "Shared Reservation Visited",
  CTAPressed = "CTAPressed",
}
export enum CalendarInteractionEvents {
  DownloadApplePass = "Download Apple Pass",
  AddedEventToCalendar = "Added Event To Calendar",
}

export enum ModalEvents {
  ToggleModal = "Toggle Modal",
}

export enum PanelEvents {
  TogglePanel = "Toggle Panel",
  PanelStep = "Panel Step",
}

export enum LegalEvents {
  CookieConsentDialogDisplayed = "Cookie Consent Dialog Displayed",
  PrivacyPolicyLinkPressed = "Privacy Policy Link Pressed",
  TermsOfServiceLinkPressed = "Terms Of Service Link Pressed",
  CookiesAllAccepted = "All Cookies Accepted",
  CookiesManagePreferences = "Cookies Manage Preferences Clicked",
  CookiesPreferencesSaved = "Cookies Preferences Saved",
}

export enum SupportEvents {
  OpenedSupportChat = "Opened Support Chat",
  ClosedSupportChat = "Closed Support Chat",
}
