import { isInEUTimezone } from "@segment/in-eu"

import WebImplementation from "./src/components/web"
import { CookiesConsentManager } from "./src/CookiesConsentManager"

const ConsentManager = CookiesConsentManager(WebImplementation)
export default ConsentManager

export { LegalLinks } from "./src/helpers/enums"
export { isInEUTimezone }
