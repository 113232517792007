import type { SettingValue } from "configcat-common"
import { createFlagOverridesFromMap } from "configcat-common"
import { ConfigCatProvider as CCProvider, PollingMode, createConsoleLogger, OverrideBehaviour } from "configcat-react"
import React, { useState } from "react"

import type { ConfigCatAppConfig } from "../types"

export type ConfigCatProviderProps = {
  config: ConfigCatAppConfig["configCat"]
  children: React.ReactNode
  logLevel?: number
  overrideBehaviour?: OverrideBehaviour
}

const FIVE_MINUTES = 300

export const ConfigCatProvider = ({
  config: { key: configCatKey, flagOverrides: overrides },
  logLevel,
  children,
  overrideBehaviour = OverrideBehaviour.LocalOnly,
}: ConfigCatProviderProps) => {
  const logger = createConsoleLogger(logLevel ?? -1)

  const [flagOverrides] = useState(() => {
    if (!overrides) return undefined

    const filteredOverrides: { [name: string]: NonNullable<SettingValue> } = {}
    Object.entries(overrides).forEach(([name, value]) => {
      if (value !== undefined) {
        filteredOverrides[name] = value
      }
    })

    return createFlagOverridesFromMap(filteredOverrides, overrideBehaviour)
  })

  return (
    <CCProvider
      sdkKey={configCatKey}
      pollingMode={PollingMode.LazyLoad}
      options={{
        cacheTimeToLiveSeconds: FIVE_MINUTES,
        logger,
        flagOverrides,
      }}>
      {children}
    </CCProvider>
  )
}
