import { useQuery } from "@tanstack/react-query"
import type { SettingValue, User } from "configcat-react"
import { useConfigCatClient } from "configcat-react"
import { useEffect } from "react"

import type { ValueOf } from "@bounce/util"

import { useFeatureFlagOverrides } from "../contexts/FeatureFlagOverridesContext"
import { trackExperimentOnce } from "../helpers/trackExperiment"
import type { AppSettings, UserMatchers } from "../types"

type AnySetting = ValueOf<AppSettings>

export type UseConfigValueArgs<TValue, TSetting extends string = AnySetting> = {
  setting: TSetting
  defaultValue: TValue
  user: UserMatchers
  ignoreUser?: boolean
}

type IsFlagValueReady = boolean

export const useConfigValue = <TValue, TSetting extends string = AnySetting>({
  setting,
  defaultValue,
  user: { id, email, country, custom },
}: UseConfigValueArgs<TValue, TSetting>): [TValue, IsFlagValueReady, unknown] => {
  const configCat = useConfigCatClient()

  const userDetails: User = {
    identifier: id,
    email: email || undefined,
    country: country || undefined,
    custom,
  }

  const {
    data: value = defaultValue,
    isLoading,
    isSuccess: hasFeatureFlagValue,
    error,
  } = useQuery({
    queryKey: ["ConfigCat", { setting, defaultValue }, userDetails],
    queryFn: () => configCat.getValueAsync(setting, defaultValue as SettingValue, userDetails),
    select: config => config?.valueOf() as TValue,
    networkMode: "offlineFirst",
    notifyOnChangeProps: ["data"],
  })

  // update value if we have a local override set
  const { flagOverrides } = useFeatureFlagOverrides()
  const valueOrOverrideValue = (flagOverrides[setting] as TValue) ?? value

  useEffect(() => {
    if (!hasFeatureFlagValue) return

    trackExperimentOnce({ setting, value: valueOrOverrideValue })
  }, [hasFeatureFlagValue, setting, valueOrOverrideValue])

  return [valueOrOverrideValue, !isLoading, error]
}
