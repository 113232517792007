import React from "react"

import { Trans, useTranslation } from "@bounce/i18n"

import type { UIImplementation } from "./types"
import { PRIVACY_POLICY_URL } from "../helpers/helpers"
import type { CookieConsentActions } from "../helpers/types"

interface CookieConsentDialogProps {
  actions: CookieConsentActions
  implementation: UIImplementation
}

const CookieConsentDialog = ({ actions, implementation }: CookieConsentDialogProps) => {
  const { t } = useTranslation("legal")

  return (
    <implementation.Container>
      <implementation.dialog.Header title={t("cookiePreferences.dialog.titleV2", "Your cookies preferences")}>
        <Trans
          ns="legal"
          i18nKey="cookiePreferences.dialog.description"
          defaults="We use cookies to improve your experience. For more details, visit our <0>Privacy Policy</0>."
          components={[<implementation.PrivacyPolicyLink key="privacy-policy-link" href={PRIVACY_POLICY_URL} />]}
        />
      </implementation.dialog.Header>
      <implementation.ActionList>
        <implementation.dialog.Actions {...actions} />
      </implementation.ActionList>
    </implementation.Container>
  )
}

export default CookieConsentDialog
