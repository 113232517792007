import type { CategoryPreferences } from "@segment/consent-manager/types/types"
import React from "react"

import { Trans, useTranslation } from "@bounce/i18n"

import type { UIImplementation } from "./types"
import { PRIVACY_POLICY_URL } from "../helpers/helpers"
import type { CookiePreferenceActions, PreferenceToggle } from "../helpers/types"

interface ConsentPreferencesProps {
  actions: CookiePreferenceActions
  preferences: CategoryPreferences
  togglePref: PreferenceToggle
  implementation: UIImplementation
}

const ConsentPreferences = ({ preferences, actions, togglePref, implementation }: ConsentPreferencesProps) => {
  const { t } = useTranslation("legal")
  const toggle = togglePref(preferences)

  const categoryTranslations: Record<string, string> = {
    essential: t("cookiePreferences.category.essential", "Strictly necessary"),
    functional: t("cookiePreferences.category.functional", "Functional cookies"),
    marketingAndAnalytics: t("cookiePreferences.category.analytics", "Analytics cookies"),
    advertising: t("cookiePreferences.category.advertising", "Advertising cookies"),
  }

  return (
    <implementation.Container>
      <implementation.preferences.Header title={t("cookiePreferences.preferences.title", "Manage cookie preferences")}>
        <Trans
          ns="legal"
          i18nKey="cookiePreferences.preferences.description"
          defaults="Bounce uses different categories of cookies to provide, protect, improve and promote our website and services. Information about these categories and their purposes is provided below. For more details, visit our <0>Privacy Policy</0>."
          components={[<implementation.PrivacyPolicyLink key="privacy-policy-link" href={PRIVACY_POLICY_URL} />]}
        />
      </implementation.preferences.Header>
      <implementation.preferences.CategoryList>
        <implementation.preferences.Category
          text={categoryTranslations.essential}
          alwaysActive={t("cookiePreferences.preferences.alwaysActive", "Always active")}
        />
        {Object.keys(preferences)
          .filter(r => r !== "essential")
          .map(p => (
            <implementation.preferences.Category
              key={p}
              text={categoryTranslations[p]}
              checked={Boolean(preferences[p])}
              onClick={() => toggle(p)}
            />
          ))}
      </implementation.preferences.CategoryList>
      <implementation.ActionList>
        <implementation.preferences.Actions {...actions} />
      </implementation.ActionList>
    </implementation.Container>
  )
}

export default ConsentPreferences
