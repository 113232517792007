import { useEffect, useState } from "react"

/**
 * This React Hook can be useful in a SSR environment to wait until be in a browser to execution some functions.
 */
export const useIsClient = () => {
  const [isClient, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  return isClient
}
