import { Trans as TransComponent, useTranslation as useTranslationHook } from "react-i18next"

export let useTranslation = useTranslationHook
export let Trans = TransComponent

/**
 * NextJS projects should use the exports from `next-i18next` instead of the ones from `react-i18next`.
 * This allows us to override the hooks used without needing to change all the logic inside this package.
 */
export const setReactI18nextExports = (newExports: {
  useTranslation: typeof useTranslationHook
  Trans: typeof TransComponent
}) => {
  useTranslation = newExports.useTranslation
  Trans = newExports.Trans
}
