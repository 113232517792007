import React from "react"

import type { ReferralCodes } from "./types"

const ReferralContext = React.createContext<ReferralCodes>({ referral: undefined, coupon: undefined })

export const useReferrals = () => {
  const context = React.useContext(ReferralContext)
  if (context === undefined) {
    throw new Error("useReferrals must be used within a ReferralsProvider")
  }
  return context
}

export default ReferralContext
