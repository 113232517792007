import { Persistency, PersistencyKeys } from "@bounce/persistency"
import { createZustandStore } from "@bounce/state-management"

import { Analytics } from "../Analytics"
import { generateAnonymousId } from "../helpers/generateAnonymousId"

type AnonymousIdState = {
  anonymousId: string
  setAnonymousId: (anonymousId: string) => void
}

export const useAnonymousId = createZustandStore<AnonymousIdState>({
  name: "useAnonymousId",
  initializer: set => ({
    anonymousId: generateAnonymousId(),
    setAnonymousId: (anonymousId: string) => {
      set({ anonymousId })
      void Analytics.setAnonymousId(anonymousId)
      void Analytics.identify(undefined, {})
    },
  }),
  persistStore: {
    key: "anonymous-id",
  },
})

Persistency.getCookie(PersistencyKeys.ANONYMOUS_ID)
  .then(id => id && useAnonymousId.getState().setAnonymousId(id))
  .catch(() => {})

export const getAnonymousId = () => useAnonymousId.getState().anonymousId
