import Cookies from "js-cookie"

import type { PersistencyType } from "./types"
import { PersistencyKeys } from "../constants"

const maybeWindow = typeof window === "undefined" ? undefined : window

// Partial persistency implementation for next.js
const Persistency: PersistencyType = {
  setItem: async (key: string, value: string) => {
    maybeWindow?.localStorage.setItem(key, value)
  },
  getItem: async (key: string): Promise<string | null> => {
    return maybeWindow?.localStorage.getItem(key) ?? null
  },
  removeItem: async (key: string) => {
    maybeWindow?.localStorage.removeItem(key)
  },
  getCookie: async (key: string): Promise<string | null> => {
    return Cookies.get(key) ?? null
  },
  setCookie: async ({ key, value, expires, domain, path }) => {
    const strict = maybeWindow?.location.protocol === "https:"
    Cookies.set(key, value.trim(), {
      expires,
      domain: domain ?? undefined,
      path,
      strict,
      sameSite: "strict",
    })
  },
  removeCookie: async ({ key, domain, path }) =>
    Persistency.setCookie({ key, value: "deleted", expires: new Date(1970, 1, 1), domain, path }),
  clear: async (skipKeys: string[] = []) => {
    maybeWindow?.localStorage.clear()
    await Promise.all(
      Object.keys(Cookies.get())
        .filter(key => !skipKeys.includes(key))
        .map(key => Persistency.removeCookie({ key })),
    )
  },
}

export { Persistency, PersistencyKeys }
