import { Container, ActionList, PrivacyPolicyLink } from "./common"
import { Actions as DialogActions, Header as DialogHeader } from "./CookieConsentDialog"
import { Actions as PreferencesActions, Header as PreferencesHeader, Category, CategoryList } from "./CookiePreferences"
import type { UIImplementation } from "../types"

const WebImplementation: UIImplementation = {
  Container,
  ActionList,
  PrivacyPolicyLink,
  dialog: {
    Header: DialogHeader,
    Actions: DialogActions,
  },
  preferences: {
    Category,
    CategoryList,
    Header: PreferencesHeader,
    Actions: PreferencesActions,
  },
}

export default WebImplementation
